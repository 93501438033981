import React, { useState, useMemo, useEffect } from 'react'
import { useAuth } from "../context/AuthContext"
import ReactPaginate from 'react-paginate'
import TableHeader from "./TableHeader"
import '../styles/data-table.scss'

function DataTable({ selectedRows, setSelectedRows }){
    const { meetingData, userData } = useAuth()
    const [rows, setRows] = useState([])
    const [filterItems, setFilterItems] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [rowCount, setRowCount] = useState(25)
    
    const columnNames = [
        {id: "date", label:"Date"}, 
        {id: "companyName", label:"Company Name"}, 
        {id: "duration", label: "Duration"},
        {id: "supportType", label: "Support Type"},
        {id: "notes", label:"Notes"}
    ]

    useEffect(() => {
        setRows(meetingData)
    },[meetingData, userData])

    //Paginate meeting rows while filtering rows (if necessary)
    const filteredRows = useMemo(() => {
        const startIndex = pageNumber * rowCount
        const endIndex = startIndex + rowCount
        return rows
          ?.filter((item) => {
            const monthPart = item.date?.slice(5, 7)
            const hasMonth = filterItems.includes(monthPart)
            const hasMatchingValue =  Object.values(item).some((val) => filterItems.includes(val))
            return !hasMonth && !hasMatchingValue
          })
          ?.slice(startIndex, endIndex)

    }, [rows, rowCount, filterItems, pageNumber])


    //Advances the page in paginated component
    const handlePageChange = ({ selected }) => { 
        setPageNumber(selected)
    }

    //Adds rows to selectedRows state -- relevant to delete function
    const addCheckboxToState = (row) => {
        if (selectedRows.some(item => item.recordID === row.recordID)) {
          setSelectedRows(selectedRows.filter(item => item.recordID !== row.recordID));
        } else {
          setSelectedRows([...selectedRows, row]);
        }
    }
      
    return(
        <>
            <table>
                <thead>
                    <tr>
                        <th></th> 
                        {columnNames.map( (col, key) => {
                            return(
                                <TableHeader 
                                    key={key}
                                    id={col.id}
                                    label={col.label}
                                    rows={rows}
                                    setRows={setRows}
                                    filterItems={filterItems}
                                    setFilterItems={setFilterItems}
                                />
                            )
                        })}
                    </tr>
                </thead>
                {rows ? (
                    <tbody>
                        {   
                            filteredRows?.map((row, id) => {
                                return(
                                    <tr key={id}>
                                        <td>
                                            <input 
                                                type="checkbox" 
                                                name={id}
                                                onChange={() => addCheckboxToState(row)}
                                            />
                                        </td>
                                        <td className="date">{row.date}</td>
                                        <td className="companyName">{row.altName ? row.altName : row.companyName}</td>
                                        <td className="duration">{row.duration}</td>
                                        <td className="supportType">{row.supportType}</td>
                                        <td className="notes">{row.notes}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody> 
                ) : (
                    <tbody>
                        <tr>
                            <td>Loading data...</td>
                        </tr>
                    </tbody>   
                )}
            </table>
            <div className='test flex-row flex-row--center'>
                <ReactPaginate
                    pageCount={Math.ceil(rows?.length / rowCount)}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                />  

                <select onChange={(e) => setRowCount(Number(e.target.value))} className="rows-per-page">
                    <option value="25">25 per page</option>
                    <option value="50">50 per page</option>
                    <option value="100" >100 per page</option>
                </select>
            </div>
            
        </>
    )
}

export default DataTable