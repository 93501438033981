import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import axiosInterceptor from '../hooks/axiosInterceptor'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [userData, setUserData] = useState('')
  const [refresh, setRefresh] = useState(false)
  const { companyList, meetingData } = userData
  const api = process.env.REACT_APP_BACKEND_KEY

  meetingData?.sort((a, b) => {
    const dateComparison = new Date(b.date) - new Date(a.date)
    if (dateComparison !== 0) {
        return dateComparison // If dates are different, sort by date
    } else {
        // If dates are the same, sort by timestamp
        return new Date(b.timeStamp) - new Date(a.timeStamp)
    }
  })

  const loadData = useCallback( async () => { 
    try {
      const userDataResponse = await axiosInterceptor.get(`${api}/userData`, {
          credentials: 'include', 
          params: { 
              email: email
          }
        });

      if (userDataResponse) {
          const userData = userDataResponse.data
          setUserData(userData) // Store user data in the state
      } else {
          console.error('Error fetching user data:', userDataResponse)
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  },[email, api])

  const checkAuthentication = useCallback( async () => {
    try {
        const response = await axiosInterceptor.get(`${api}/auth`, {
          withCredentials: true
        })

        if (response.status === 200) {
            setUsername(response.data.username)
            setEmail(response.data.email)
            setIsAuthenticated(true)
        } else { 
          setIsAuthenticated(false)
        }
    } catch (error) {
        console.error('Error checking authentication:', error)
        setIsAuthenticated(false)
    }
  },[api])


  const logout = async () => {
    try{ 
        const response = await axiosInterceptor.post(`${api}/logout`, {
            credentials: 'include'
        })
        if(response.status === 200){ 
            setIsAuthenticated(false)
        }
    } catch(error) {
        console.error('There was trouble signing out:', error)
    }
  }

  const toggleRefresh = () => { 
    setRefresh(!refresh)
  }
  
  useEffect(() => {
    checkAuthentication()
  },[checkAuthentication]);

  useEffect(() => { 
    if(isAuthenticated){
      loadData()
    }
  }, [isAuthenticated, refresh, loadData])


  const authContextValue = {
    checkAuthentication,
    isAuthenticated,
    toggleRefresh,
    logout,
    email,
    username,
    userData,
    companyList,
    meetingData
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}
