import axiosInterceptor from './axiosInterceptor'

const deleteMeetingData = async (selectedRows) => { 
    const api = process.env.REACT_APP_BACKEND_KEY
    try{ 
        await axiosInterceptor.post(`${api}/delete`, selectedRows, {
            credentials: 'include'
        })
    } catch(error){
        console.error(error)
    }
}

export default deleteMeetingData