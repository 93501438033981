import React from "react"
import { Routes, Route } from "react-router-dom"
import { useAuth } from "./context/AuthContext"
import AuthForm from "./components/AuthForm"
import Dashboard from "./components/Dashboard"
import './styles/App.scss';

function App() {
  const { isAuthenticated } = useAuth()

  return (
    <main className="App">
        <Routes>
          <Route path="/" element={isAuthenticated ? <Dashboard /> : <AuthForm />} />
        </Routes>
    </main>
  );
}

export default App;
