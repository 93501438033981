import { useMemo, useEffect, useCallback } from 'react'
import '../styles/reports.scss'

function ReportsTable({ tableName, data, currentMonth, currentYear, hourType, setTotal }) {

    //Filter data by currently selected month && year
    const filterData = useCallback(() => { 
        return data?.filter(item => {
            const month = Number(item.date.slice(5,7)) - 1
            const year = item.date.slice(0,4)

            const isMatchingMonth = month === Number(currentMonth)
            const isMatchingYear = year === currentYear
            const isCoachingMeeting = hourType.includes(item.supportType)
            return isMatchingMonth && isMatchingYear && isCoachingMeeting
        })
    },[currentMonth, currentYear, hourType, data])

    //Creates a 'pivot table' of coaching hours based on company name
    const aggregateData = useCallback((filteredData) => { 
        return filteredData?.reduce((accumulator, current) => { 
            const companyName = current.companyName
            const coachingHours = current.duration
            const altName = current.altName
            const supportType = current.supportType
            const index = companyName === "Foresight" ? accumulator.findIndex( item => item.supportType === supportType) : accumulator.findIndex( item => item.companyName === companyName)

            if (index === -1) {
                accumulator.push({ companyName: companyName, altName: altName, supportType: supportType, duration: coachingHours }) // If company doesn't exist in the accumulator, add a new entry
            } else {
                accumulator[index].duration += coachingHours // If company exists, update the coachingHours count
            }
            return accumulator
        }, [])
    },[])

    //Run filter and aggregate data - then sort it 
    const filteredHours = useMemo(() => {
        const filteredData = filterData()
        const aggregatedData = aggregateData(filteredData)
        aggregatedData?.sort((a, b) => a.companyName.localeCompare(b.companyName))
        return aggregatedData
    }, [filterData, aggregateData])

    //Send total data to Reports Component 
    useEffect(() => {
        setTotal && setTotal(filteredHours?.reduce((acc, item) => acc + item.duration, 0))
    }, [filteredHours, setTotal])
    
    return(
        <table id={`${tableName}-table`}>
            <thead>
                <tr>
                    <th className='column--first'>Company Name</th>
                    <th className="column--second">Duration</th>
                </tr>
            </thead>
            <tbody>
                {
                    filteredHours?.map( (row, i) => { 
                        return(
                            <tr key={i}>
                                <td className="column--first">{row.companyName === "Foresight" ? row.supportType : row.companyName}</td>
                                <td className="column--second">{row.duration}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default ReportsTable