import React, { useState, useEffect } from 'react'
import DOMPurify from 'dompurify'
import axios from 'axios'
import { useAuth } from '../context/AuthContext'
import logo from '../assets/fs-logo-secondary.png'
import '../styles/auth-form.scss'

function AuthForm(){ 
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const { checkAuthentication } = useAuth()
    
     // Reset error message on input change
    useEffect(() => {
        setErrorMsg('');
    }, [username, password]);
    
    const isValidEmail = (email) => { 
        const validationRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
        return validationRegex.test(email);
    }

    //Send credentials to server to handle authentication
    const handleSubmit = (e) => { 
        e.preventDefault()
        setLoading(true)
        const api = process.env.REACT_APP_BACKEND_KEY

        if(!isValidEmail(username)){
            setErrorMsg("Email or password is invalid")
            return
        }

        const authenticateUser = async () => { 
            try{ 
                const sanitizedUsername = DOMPurify.sanitize(username)
                const sanitizedPassword = DOMPurify.sanitize(password)
                const response = await axios.post(`${api}/loginUser`, {
                    username: sanitizedUsername,
                    password: sanitizedPassword
                  }, {
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    withCredentials: true
                })
  
                if(response?.status === 200){ 
                    setUsername('')
                    setPassword('')
                    checkAuthentication()
                }

            } catch(error){
                console.error('This is the error:', error.response.status)
                //Return the appropriate error message to login form
                if(error.response.status === 403){
                    setErrorMsg("Username or Password is incorrect")
                } else if(error.response.status === 429){
                    setErrorMsg("Too many failed login attempts. Your account has been temporarily locked for 5 minutes.")
                } else if (!error.response) {
                    setErrorMsg("No Server Response");
                } else {
                    setErrorMsg("Login Failed");
                }
            } finally {
                setLoading(false)
            }
        }

        authenticateUser()    
    }


    return(
        <div className="form-container flex-column flex-column--center">
            <div className='flex-column flex-column--center'> 
                <img src={logo} alt="Foresight Logo" />
                <h2>EIR Meeting Tracker</h2>
                <form id="auth-form" className="flex-column flex-column--center" onSubmit={handleSubmit}>
                    {loading && !errorMsg ? (
                        <div id="loading" className="flex-column flex-column--center"> 
                            <div id="spinner" />
                            <p>Please wait...</p>
                        </div>
                    ) : (
                        <div className="flex-column flex-column--center">
                            <input 
                                type="email"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder='Email Address' 
                                required
                            />
                            <input 
                                type="password" 
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} 
                                placeholder='Password'
                                required
                            />
                            
                        </div>
                    )}
                    <button className="btn--primary">Log In</button>
                    <p className={errorMsg ? "errorMsg" : "hidden"}>{errorMsg}</p>
                </form>
               
            </div> 
        </div>
    )
}

export default AuthForm