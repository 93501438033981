import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useAuth } from "../context/AuthContext";
import sortIcon from '../assets/sort.svg'
import checkIcon from '../assets/checkIcon.svg'
import filterIcon from '../assets/filter.svg'
import '../styles/data-table.scss'

function TableHeader({id, label, rows, setRows, filterItems, setFilterItems}){ 
    const { meetingData } = useAuth()
    const [dropdown, setDropdown ] = useState(false)
    const [dropdownData, setDropdownData] = useState([])
    const [filtered, setFiltered] = useState(false)
    const dropdownRef = useRef(null)
    const innerRef = useRef(null)
    
    // eslint-disable-next-line
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const uniqueValues = useMemo(() => {
        const values = [...new Set(dropdownData?.map(item => item[id]))]
        if(id === 'date'){
            return months
        } else{ 
            return values
        } 
    }, [dropdownData, id, months])

   
    //Load a click event to detect if clickevent is outside of header dropdown
    useEffect(() => {
        document.getElementById("dashboard").addEventListener("click", toggleDropdown)
        setDropdownData(meetingData) //Set meetingData to be parsed into dropdown components
    },[meetingData])

    useEffect(() => {
        let values = uniqueValues

        if(id === 'date'){ 
            values = uniqueValues.map( item => { 
                const month = months.indexOf(item) + 1
                return values = month.toString().padStart(2, '0')
            } )
        }

        const isFiltering = values.some(value => filterItems.includes(value))
        setFiltered(isFiltering)
    },[filterItems, uniqueValues, id, months])

    //Toggle header dropdowns
    const toggleDropdown = (e) => { 
        (
            dropdownRef.current && 
            !dropdownRef.current.contains(e.target) &&
            !innerRef.current.contains(e.target)
        ) 
            ? setDropdown(false)
            : setDropdown(true)
    }

    //Sort rows from A -> Z and Z -> A
    const sortRows = (col, dir) => {
        const sortedData = [...rows].sort((a, b) => 
           dir === 'ascending'
            ? a[col] > b[col] ? 1 : -1 
            : a[col] < b[col] ? 1 : -1 
        )
        setRows(sortedData)
    }

    //Select items to include in the filterItems state array
    const toggleFilterItem = (item) => { 
        let convertedItem 

        if(id === 'date'){
            const month = months.indexOf(item) + 1
            convertedItem = month.toString().padStart(2, '0')
        } else {
            convertedItem = item
        }
        
        filterItems.includes(convertedItem)
            ? setFilterItems(filterItems.filter((i) => i !== convertedItem))
            : setFilterItems([...filterItems, convertedItem])
    }

    const clearFilterItems = () => { 
        let values = uniqueValues

        if(id === 'date'){ 
            values = uniqueValues.map( item => { 
                const month = months.indexOf(item) + 1
                return values = month.toString().padStart(2, '0')
            } )
        }

        setFilterItems(values)
    }

    const selectAllItems = () => { 
        setFilterItems([])
    }


    return(
        <th id={`${id}-header`} className={id}>
            <span>{label}</span>
            <img
                id={id}
                className="sort icon icon--small" 
                ref={dropdownRef}
                src={ filtered ? filterIcon : sortIcon} 
                alt={`Sort ${id}`} 
            />
            <div ref={innerRef} className={dropdown ? "filter-dropdown" : "hidden"}>
                <p onClick={() => sortRows(id, 'ascending')}>Sort A - Z</p>
                <p onClick={() => sortRows(id, 'descending')}>Sort Z - A</p>
                
                <div className="filter-header">
                    <span>Filter</span>
                    <div className="flex-row">
                        <p onClick={()=> selectAllItems()}>Select All</p>
                        <p onClick={()=> clearFilterItems()}>Clear</p>
                    </div>
                </div>
                
                {
                    uniqueValues.map( (item, i) => {
                        let displayItem = item
                        if(id === 'date'){
                            const month = months.indexOf(item) + 1
                            displayItem = month.toString().padStart(2, '0')
                        } 

                        return(
                            <div id={item} key={i} onClick={() => toggleFilterItem(item)} className={`filter-item flex-row ${id}`}> 
                                <img 
                                    className={filterItems.includes(displayItem) ? "hidden-checkmark icon icon--small" : "icon icon--small" }
                                    src={checkIcon} 
                                    alt="Filter Item Selected" 
                                />
                                <p id={item}>{item}</p>
                            </div>
                        )
                    })
                }
            </div>
        </th>
    )
}

export default TableHeader