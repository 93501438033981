import axiosInterceptor from './axiosInterceptor'

const editMeetingData = async (record) => { 
    const api = process.env.REACT_APP_BACKEND_KEY
    try{ 
        await axiosInterceptor.post(`${api}/edit`, record, {
            credentials: 'include'
        })
    } catch(error){ 
        console.error(error)
    }
}

export default editMeetingData