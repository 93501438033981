import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../context/AuthContext'
import Faq from './FAQ'
import logoSecondary from "../assets/fs-logo-secondary.png"
import hamburger from '../assets/hamburger.svg'
import '../styles/main-nav.scss'

function MainNav({ setDashboard}) {
    const [faq, setFaq] = useState(false)
    const [mobileDropdown, setMobileDropdown] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const { logout } = useAuth()

    const handleWindowSizeChange = useCallback(() => {
        setScreenWidth(window.innerWidth);
        if(screenWidth > 768) setMobileDropdown(false)
    },[screenWidth])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [handleWindowSizeChange]);



    return (
        <nav id="main-nav" className="flex-row flex-row--space">
            {faq && <Faq setFaq={setFaq}/>}
            <img className="logo" src={logoSecondary} alt="Foresight Logo" />
            <img 
                    src={hamburger} 
                    alt="Toggle mobile menu" 
                    className="hamburger-menu icon icon--lg"
                    onClick={() => setMobileDropdown(!mobileDropdown)}
            />
            <div className={mobileDropdown ?  "user-dropdown-mobile" : "user-dropdown" }>
                <p onClick={() => setFaq(!faq)}>FAQ</p>
                <p onClick={() => setDashboard(false)}>Reports</p>
                <p onClick={() => logout()}>Logout</p>
            </div>
        </nav>
    )
}

export default MainNav