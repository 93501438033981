import { useState } from 'react'
import faq from '../content/faq'
import chevronIcon from '../assets/chevron.svg'
import exitIcon from '../assets/exit.svg'
import '../styles/modal.scss'
 
function Faq({ setFaq }){
    const [accordion, setAccordion] = useState([0])
    
    const toggleAccordion = (e) => { 
        const clicked = Number(e.target.id)

        accordion.includes(clicked)
            ? setAccordion(accordion.filter( i => i !== clicked))
            : setAccordion([...accordion, clicked])
    }

    return(
        <section className="modal-background flex-row flex-row--center">
            <div className='modal-inner dashboard flex-column'>
                <img onClick={() => setFaq(false)} className="exit icon icon--md" src={exitIcon} alt="Exit Add Hours Modal" />
                <h2>FAQ</h2>
                <div className="accordian-container">
                    {faq.map( (item, id) => {
                        return(
                            <div className="accordion-section flex-column">
                                <div className="flex-row flex-row--space">
                                    <h3>{item.faq}</h3>
                                    <img 
                                        onClick={(e) => toggleAccordion(e)}
                                        id={id} 
                                        className={accordion.includes(id) ? 'chevron-rotate icon icon--small' : 'icon icon--small'} 
                                        src={chevronIcon} 
                                        alt="Collapse/Open FAQ Section" 
                                    />
                                </div>
                                <div className={accordion.includes(id) ? 'accordion accordion--open' : 'accordion'}>
                                    {item.content.map( paragraph => { 
                                        return(
                                            <div className='accordion-text'>
                                                {paragraph.title && <p className="title">{paragraph.title}</p>}
                                                <p>{paragraph.text}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        ) 
                    })}
                </div>   
            </div>
        </section>
    )
}

export default Faq