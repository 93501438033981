import axiosInterceptor from './axiosInterceptor'

const addMeetingData = async (meetingData) => { 
    const api = process.env.REACT_APP_BACKEND_KEY
    try{ 
        const response = await axiosInterceptor.post(`${api}/add`, meetingData, {
            credentials: 'include',
        })

         if (response.status === 200) {
            console.log("Meeting data added successfully!")
        } else {
            console.log("Unexpected response status:", response.status)
        }
    } catch(error){ 
        console.error(error)
    }
}

export default addMeetingData