import React, { useState, useCallback, useEffect } from 'react'
import { useAuth } from "../context/AuthContext"
import DataTable from "./DataTable"
import Modal from './Modal'
import HoursForm from './HoursForm'
import deleteMeetingData from '../hooks/deleteMeeting'
import '../styles/coaching-hours.scss'

function CoachingHours(){ 
    const { toggleRefresh } = useAuth()
    const [selectedRows, setSelectedRows] = useState([])
    const [modal, setModal] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [isMobile, setIsMobile] = useState(false)
    const [successMsg, setSuccessMsg] = useState(true)

    const handleWindowSizeChange = useCallback(() => {
        setScreenWidth(window.innerWidth); 
    },[])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        if(screenWidth < 640) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
  
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [handleWindowSizeChange, screenWidth]);

    useEffect(() => { 
        if(!successMsg){ 
            setTimeout(() => {
                setSuccessMsg(true)
            }, 1000);
        }
    }, [successMsg])



    //Write a try block to delete meetings 
    const deleteMeetings = () => {
        deleteMeetingData(selectedRows)
        setSelectedRows([])
        //Reset checkboxes in DOM
        const checkboxes = document.querySelectorAll('input[type="checkbox"]')
        checkboxes.forEach( checkbox => checkbox.checked = false)
        toggleRefresh()
    }

    return(
        <section id='coaching-hours'>
            {isMobile ? (
                <>
                    <h2>Meeting Tracker Mobile</h2>
                    <HoursForm setModal={setSuccessMsg}/>
                    { !successMsg ? <p>Hours added successfully</p> : null}
                </>
            ) : (
                <>
                    {modal &&
                        <Modal 
                            setModal={setModal} 
                            selectedRows={ selectedRows.length === 1 && selectedRows} 
                            setSelectedRows={setSelectedRows}
                        />
                    } 

                    <div id='button-container'>
                        {selectedRows.length < 1 ? (
                            <button className='btn--primary' onClick={() => setModal(!modal)}>Add Hours</button>
                        ) : (
                            <div className="flex-row flex-row--space">
                                {selectedRows.length === 1 && <button className='btn--edit' onClick={() => setModal(!modal)}>Edit Row</button>}
                                <button className='btn--delete' onClick={() => deleteMeetings()}>{ selectedRows.length > 1 ? "Delete Rows" : "Delete Row"}</button>
                            </div>
                        )}  
                    </div>
                    <DataTable 
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    />
                </>
            )}
        </section>
    )
}

export default CoachingHours